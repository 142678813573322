@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@400;700&display=swap');

.education-section {
  background-color: #f4f4f9;
  font-family: 'Roboto', sans-serif;
  padding: 50px 0;
  position: relative;
}

h1.contact-page-title {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
  letter-spacing: 3px;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
  padding: 20px 0;
  border-bottom: 5px solid #0072ff;
}

.education-item {
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #d3c4af;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

h2.institution {
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  color: #4a2c2a;
  font-weight: 700;
}

p, li {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.7;
}

.education-logo {
  max-width: 120px;
  border-radius: 10px;
  filter: grayscale(100%);
}

.experience-item {
  background: #fff;
  border: 1px solid #e6ded2;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.experience-item h5 {
  font-size: 1.5rem;
  font-family: 'Playfair Display', serif;
  color: #7d2535;
  font-weight: 600;
}

.experience-item p {
  font-size: 1rem;
  color: #4a2c2a;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
}

