/* navbar.css */

.navbar {
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
}

.navbar-light .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
}

.navbar-logo {
    height: 50px;
    width: auto;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%2833, 33, 33%2C 0.5%29' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    filter: invert(0.7);
}

.navbar-nav .nav-item {
    margin-left: 1rem;
}

.navbar-nav .nav-link {
    color: #333333;
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease-in-out;
}

.navbar-nav .nav-link:hover {
    color: #007bff;
    text-decoration: none;
}

@media (max-width: 992px) {
    .navbar {
        padding: 1rem;
    }

    .navbar-collapse {
        background-color: #ffffff;
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        z-index: 1;
    }

    .navbar-toggler {
        outline: none;
    }

    .navbar-nav {
        flex-direction: column;
    }

    .nav-item {
        margin-left: 0;
        border-bottom: 1px solid #f1f1f1;
    }

    .nav-link {
        text-align: center;
        padding: 1rem;
    }

    .collapse.show {
        display: block;
    }
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:focus {
    color: #007bff;
}

html {
    scroll-behavior: smooth;
}
