.research-page {
  font-family: 'Roboto', sans-serif;
  padding: 50px 0;
  position: relative;
  }
  
  .research-title {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    color: #2c3e50;
    text-transform: uppercase;
    letter-spacing: 3px;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
    padding: 20px 0;
    border-bottom: 5px solid #0072ff;
  }
  .research-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1200px; /* Ensure the same width as in projects */
  }
  
  .project-card {
    border: 1px solid #000;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: calc(100% - 5px);
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    transform: translateY(0);
    transition: box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 1);
  }
  
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-info {
    padding: 20px;
    text-align: center;
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .project-info h2 {
    font-size: 1.5rem;
    color: #7d2535;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
  }
  
  .project-info p {
    font-size: 1rem;
    color: black;
    margin-bottom: 10px;
  }
  
  .skills-used {
    margin-bottom: 15px;
  }
  
  .skills-used h3 {
    font-size: 1.2rem;
    color: #7d2535;
    margin-bottom: 10px;
  }
  
  .skills-used ul {
    list-style-type: none;
    padding: 0;
  }
  
  .skills-used ul li {
    font-size: 0.9rem;
    color: white;
    display: inline-block;
    margin-right: 5px;
    padding: 5px 8px;
    background: #7d2535;
    border-radius: 8px;
  }
  
  .project-link {
    text-decoration: underline;
    font-size: 1rem;
    color: #0072ff;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .project-link:hover {
    color: #0056b3;
    cursor: pointer;
  }
  
  @media (max-width: 800px) {
    .research-container {
      grid-template-columns: 1fr; /* One column for tablet and mobile */
    }
  }