.projects-page {
  text-align: center;
  color: #4a2c2a;
  background-color: #f7f2f2;
  padding: 2rem;
}

h1.page-title {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
    letter-spacing: 3px;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
    padding: 20px 0;
    border-bottom: 5px solid #0072ff;
}

.project-card {
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  padding: 0; /* Remove any padding */
  background: none; /* Remove background color */
  border: none;
}

.project-card img {
  width: 100%;
  height: 100%;
  display: block; 
  object-fit: contain;
  transition: transform 0.3s ease;
}

.project-card:hover img {
  transform: scale(1.05);
  filter: brightness(0.85);
}

.col-lg-4, .col-md-6, .col-sm-12 {
  padding: 0;
  margin: 0;
}

.project-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-content {
  background-color: #ffffff;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  padding: 2rem;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  transform: translateY(-100%);
  animation: slideDown 0.5s forwards ease-in-out;
}

@keyframes slideDown {
  to {
    transform: translateY(0);
  }
}

.close-dialog {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #4a2c2a;
  cursor: pointer;
}

.github-link-container {
  margin-top: 2rem;
}

.github-button {
  background-color: #0072ff;
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.github-button:hover {
  background-color: #555;
}