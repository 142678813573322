/* BlogCard CSS */
#each-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.blog-card {
  max-height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.card-img {
  height: 150px;
  object-fit: cover;
}

.card-body-scrollable {
  flex: 1;
  overflow-y: auto; 
  max-height: 200px; 
}

  