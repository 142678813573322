 .section-title {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
    letter-spacing: 3px;
    background: linear-gradient(90deg, #00c6ff, #0072ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
    padding: 20px 0;
    border-bottom: 5px solid #0072ff;
  }
  .experience-row{
    gap: 30px;
  }
  .experience-section {
    background: #f4f3f1;
  font-family: 'Roboto', sans-serif;
  padding: 50px 0;
  position: relative;
  }
  
  .section-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #343a40;
  }
  
  .experience-card {
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: calc(100% - 5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    transform: translateY(0);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .experience-card:hover{
    box-shadow: 0 10px 20px rgba(0, 0, 0, 1);
  }
   
  .experience-image {
  width: 200px;
  height: auto; 
  object-fit: contain; 
  }
  
  .experience-info {
    padding: 15px;
    flex: 1;
  }
  
  .duration {
    color: #6c757d;
    font-style: italic;
  }
  
  .skills-used {
    margin-top: 10px;
  }
  
  .skills-used h4 {
    margin-bottom: 5px;
  }
  
  .skills-used ul {
    list-style-type: none;
    padding: 0;
  }
  
  .skills-used li {
    background-color: #e9ecef;
    margin: 5px 0;
    padding: 5px;
    border-radius: 5px;
  }
    .experience-info h2 {
        color: #7d2535 !important; 
      }

  