.paper-section {
    background-color: #f4f4f9;
  font-family: 'Roboto', sans-serif;
  padding: 50px 0;
  position: relative;
  text-align: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .description {
    font-size: 1.2rem;
    color: #34495e;
    margin: 20px 0;
    animation: fadeIn 1s ease-in-out;
  }
  
  .note {
    font-size: 1rem;
    color: #7f8c8d;
    margin-top: 10px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .illustration {
    margin-top: 30px;
  }
  
  .illustration img {
    max-width: 80%;
    height: auto;
    animation: zoomIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes zoomIn {
    from {
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  