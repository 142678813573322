@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Poppins:wght@400;500&display=swap');
body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #f4f4f9;
}

.home {
  min-height: 100vh; /* Make sure it takes the full height of the viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
}

.navbar {
  margin-bottom: 10px; 
  z-index: 1000; 
}

.hero {
  padding: 60px 20px; /* Add padding to top to push content down */
  text-align: center;
  background-color: #f4f4f9; /* Ensure background color matches */
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #007bff;
}

h1 {
  font-family: 'Courier New', monospace;
  color: #333;
  margin-bottom: 0.5rem;
}

.lead {
  font-style: italic;
  color: #555;
  margin-bottom: 1rem;
}

p {
  color: #6c757d;
}

.text-muted {
  color: #6c757d !important;
  max-width: 600px;
  margin: 0 auto;
}

.container {
  padding: 2rem;
}

.btn-outline-primary {
  border: 2px solid #007bff;
  color: #007bff;
  padding: 0.75rem 1.5rem;
  font-family: 'Courier New', monospace;
  transition: all 0.3s ease;
}
.btn-outline-primary:hover {
  background-color: #007bff;
  color: white;
}
.home .text-center {
    padding-top: 200px;
  }

    